import {
  Component,
  ComponentRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  style,
} from '@angular/animations';

@Component({
  selector: 'modal',
  standalone: true,
  imports: [],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent<T> implements OnInit, OnDestroy {
  protected vcr = inject(ViewContainerRef);
  protected modalService = inject(ModalService);
  protected animationBuilder = inject(AnimationBuilder);

  public afterCloseCallback = (data?: any) => {};
  public onCloseCallback = (data?: any) => {};
  public temp!: TemplateRef<any>;
  public comp!: Type<any>;
  public ref!: ComponentRef<ModalComponent<T>>;
  public data: any = null;
  public backdrop: HTMLElement = null;
  type: 'modal' | 'sheet' | 'side' = 'modal';
  classList: Array<string> = [];
  animation: {
    duration: string;
    delay?: string;
  } | null = { duration: '500ms' };

  public componentRef?: ComponentRef<T>;

  @HostListener('document:keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.close(null);
    }
  }

  ngOnInit(): void {
    if (!!this.comp) {
      this.vcr.clear();
      this.componentRef = this.vcr.createComponent(this.comp);
      this.vcr.element.nativeElement.appendChild(
        this.componentRef.location.nativeElement
      );
      (this.componentRef.instance as any).initialData = this.data;
      (this.componentRef.instance as any).modalComponent = this;
    }
    if (this.type === 'modal') {
      (this.vcr.element.nativeElement as HTMLElement).classList.add(
        'top-0',
        'left-0',
        'md:top-1/2',
        'md:left-1/2',
        'min-w-svw',
        'max-h-[100svh]',
        'md:min-w-[50svw]',
        'md:max-h-[80svh]',
        'md:-translate-x-1/2',
        'md:-translate-y-1/2',
        ...(this.classList || [])
      );
      if (this.animation) {
        const computedStyle = getComputedStyle(this.vcr.element.nativeElement);
        const animation: AnimationFactory = this.animationBuilder.build([
          style({ top: '100%', opacity: 0 }),
          animate(
            `${this.animation.duration} ease-out`,
            style({ top: computedStyle.top, opacity: 1 })
          ),
        ]);
        const player = animation.create(this.vcr.element.nativeElement);
        player.play();
      }
    } else if (this.type === 'side') {
      (this.vcr.element.nativeElement as HTMLElement).classList.add(
        'top-0',
        'left-0',
        'bottom-0',
        'min-w-[80svw]',
        'max-w-[80svw]',
        'w-full',
        'md:min-w-80',
        'md:max-w-80',
        ...(this.classList || [])
      );
      if (this.animation) {
        const animation: AnimationFactory = this.animationBuilder.build([
          style({ transform: 'translateX(-100%)', opacity: 0 }),
          animate(
            `${this.animation.duration} ease-out`,
            style({ transform: 'translateX(0)', opacity: 1 })
          ),
        ]);
        const player = animation.create(this.vcr.element.nativeElement);
        player.play();
      }
    } else {
      (this.vcr.element.nativeElement as HTMLElement).classList.add(
        '!sticky',
        'bottom-0',
        'left-0',
        'md:left-1/2',
        'w-svw',
        'md:w-[50svw]',
        'md:-translate-x-1/2',
        ...(this.classList || [])
      );
      if (this.animation) {
        const animation: AnimationFactory = this.animationBuilder.build([
          style({ bottom: '-100%', opacity: 0 }),
          animate(
            `${this.animation.duration} ease-out`,
            style({ bottom: '0', opacity: 1 })
          ),
        ]);
        const player = animation.create(this.vcr.element.nativeElement);
        player.play();
      }
    }
  }

  ngOnDestroy() {}

  protected destroyComponent(callback?: () => void) {
    if (this.backdrop) {
      document.body.classList.remove('overflow-hidden');
      this.backdrop.classList.add('hidden');
    }
    if (!this.animation) {
      callback?.();
      this.ref.destroy();
      return false;
    }
    if (this.type === 'modal') {
      const computedStyle = getComputedStyle(this.vcr.element.nativeElement);
      const animation: AnimationFactory = this.animationBuilder.build([
        style({ top: computedStyle.top, opacity: 1 }),
        animate(
          `${this.animation.duration} ease-out`,
          style({ top: '100%', opacity: 0 })
        ),
      ]);
      const player = animation.create(this.vcr.element.nativeElement);
      player.play();
      player.onDone(() => {
        callback?.();
        this.ref.destroy();
      });
    } else if (this.type === 'side') {
      const animation: AnimationFactory = this.animationBuilder.build([
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(
          `${this.animation.duration} ease-out`,
          style({ transform: 'translateX(-100%)', opacity: 0 })
        ),
      ]);
      const player = animation.create(this.vcr.element.nativeElement);
      player.play();
      player.onDone(() => {
        callback?.();
        this.ref.destroy();
      });
    } else {
      const animation: AnimationFactory = this.animationBuilder.build([
        style({ bottom: '0', opacity: 1 }),
        animate(
          `${this.animation.duration} ease-out`,
          style({ bottom: '-100%', opacity: 0 })
        ),
      ]);
      const player = animation.create(this.vcr.element.nativeElement);
      player.play();
      player.onDone(() => {
        callback?.();
        this.ref.destroy();
      });
    }
    return false;
  }

  public close(data?: any) {
    this.onCloseCallback(data);
    this.destroyComponent();
  }

  public onClose(calback: (data?: any) => void) {
    this.onCloseCallback = calback;
  }

  public afterClose(calback: (data?: any) => void) {
    this.afterCloseCallback = calback;
  }
}
