import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { LegalService } from '../services/legal.service';
import { map, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export const legalPagesResolver: ResolveFn<boolean> = (route, state) => {
  const legalService = inject(LegalService);
  const translateService = inject(TranslateService);

  const serviceMap = {
    privacy: legalService.getPrivacy(),
    terms: legalService.getTerms(),
    kvkk: legalService.getKVKK(),
  };
  const service = serviceMap[route.data['key']];
  if (!route.data['key'] || !serviceMap[route.data['key']]) {
    return false;
  }
  return service.pipe(
    take(1),
    map((data) => {
      return {
        content: data,
        title: translateService.instant(`profilePage.${route.data['key']}`),
      };
    })
  );
};
