<a
  class="flex flex-col w-[74px] items-center justify-center cursor-pointer"
  [routerLink]="['/'] | localize"
>
  <span class="liga-home3 text-2xl leading-none"> </span>
  <span class="text-xs">{{ "home" | translate }}</span>
</a>
<a
  class="flex flex-col w-[74px] items-center justify-center cursor-pointer"
  [routerLink]="['/explore'] | localize"
>
  <span class="iconbz-search text-2xl leading-none"></span>
  <span class="text-xs">{{ "explore" | translate }}</span>
</a>
<a
  class="flex flex-col w-[74px] items-center justify-center cursor-pointer"
  [routerLink]="['/profile', 'saves'] | localize"
>
  <span class="liga-bookmark text-2xl leading-none"></span>
  <span class="text-xs">{{ "bookmark" | translate }}</span>
</a>
<a
  class="flex flex-col w-[74px] items-center justify-center cursor-pointer"
  [routerLink]="['/profile'] | localize"
>
  <span class="liga-user text-2xl leading-none"> </span>
  <span class="text-xs">{{ "profile" | translate }}</span>
</a>
<a
  class="flex flex-col w-[74px] items-center justify-center cursor-pointer"
  [routerLink]="['/wallet'] | localize"
>
  <span class="liga-wallet text-2xl leading-none"></span>
  <span class="text-xs">{{ "wallet" | translate }}</span>
</a>
