import { HttpClient } from '@angular/common/http';
import { effect, inject, Injectable, signal } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from '../environments/environment';
import { StringHelper } from '../helpers/string.helper';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CurrentCityService {
  public city = signal('');
  public nearByCities = signal(false);
  protected http = inject(HttpClient);
  protected translateService = inject(TranslateService);
  protected router = inject(Router);
  public $cities = new BehaviorSubject([]);
  public cities$ = this.$cities.asObservable();
  protected $selectedCity = new BehaviorSubject(null);
  public selectedCity$ = this.$selectedCity
    .asObservable()
    .pipe(filter(Boolean), distinctUntilChanged());
  protected $nearByCities = new BehaviorSubject(null);
  public nearByCities$ = this.$nearByCities
    .asObservable()
    .pipe(distinctUntilChanged());
  public cityOptions$ = combineLatest([this.selectedCity$, this.nearByCities$]);
  constructor() {
    this.city.set(
      localStorage.getItem('lastKnownCity') || environment.initialCity
    );
    this.nearByCities.set(
      (localStorage.getItem('nearByCities') || 'false') === 'true'
    );
    effect(
      () => {
        const currentCity = this.city();
        if (currentCity) {
          this.$selectedCity.next(currentCity);
          localStorage.setItem('lastKnownCity', currentCity);
        }
      },
      { allowSignalWrites: true }
    );
    effect(
      () => {
        const nearByCities = this.nearByCities();
        this.$nearByCities.next(nearByCities);
        localStorage.setItem('nearByCities', nearByCities ? 'true' : 'false');
      },
      { allowSignalWrites: true }
    );
  }
  public findCurrentCity() {
    if (
      (this.city() && this.city() !== 'Türkiye') ||
      environment.multiCityDisabled
    ) {
      return;
    }
    const localCity = localStorage.getItem('lastKnownCity');
    if (localCity) {
      this.city.set(localCity);
      return;
    }
    new Observable((observer) =>
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          observer.next({ lat, lon });
        },
        () => {
          this.city.set('Türkiye'), observer.next(null);
        }
      )
    )
      .pipe(
        switchMap((pos: any) => {
          if (!pos) {
            return of(null);
          }
          const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${pos.lat}&lon=${pos.lon}`;
          return this.http.get(url).pipe(
            map((data: any) => {
              return (
                data?.address?.province ||
                data?.address?.city ||
                data?.address?.town ||
                data?.address?.village
              );
            })
          );
        }),
        switchMap((city: string) =>
          city
            ? this.getCities().pipe(
                map((cities) => {
                  const cityObj: any = cities.find(
                    (item: any) =>
                      StringHelper.slugify(item.name) ===
                      StringHelper.slugify(city)
                  );
                  return cityObj?.name || '';
                })
              )
            : of('')
        )
      )
      .subscribe((city: string) => {
        const lang = `${
          this.translateService.currentLang
        }-${this.translateService.currentLang.toUpperCase()}`;
        let pathName = location.pathname;
        if (
          decodeURIComponent(pathName).includes(
            this.city().toLocaleLowerCase(lang)
          ) ||
          decodeURIComponent(pathName) ===
            `/${this.translateService.currentLang}`
        ) {
          pathName = decodeURIComponent(pathName).replace(
            this.city().toLocaleLowerCase(lang),
            city.toLocaleLowerCase(lang)
          );
          this.router.navigateByUrl(pathName);
        }
        this.city.set(city || 'Türkiye');
      });
  }
  getCities() {
    if (this.$cities.getValue()?.length) {
      return this.cities$;
    }
    this.http
      .get(`${environment.apiHost}City`)
      .pipe(map((response: any) => response.data))
      .subscribe((resp) => {
        this.$cities.next(resp);
      });
    return this.cities$;
  }
  validCity(citySlug: string, setCity = false) {
    return this.getCities().pipe(
      filter((cities) => Boolean(cities) && Boolean(cities.length)),
      map((cities: any[]) => {
        return cities.find(
          (city: any) =>
            (city.slug || StringHelper.slugify(city.name)) === citySlug
        )?.name;
      }),
      map((city) => {
        if (city && setCity && !environment.multiCityDisabled) {
          this.city.set(city);
        }
        return Boolean(city);
      })
    );
  }
}
