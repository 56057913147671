import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from '../../environments/environment';
import { CurrentCityService } from '../current-city.service';

@Injectable({
  providedIn: 'root',
})
export class SiteDataService {
  protected http = inject(HttpClient);
  protected currentCityService = inject(CurrentCityService);

  banLoading = signal(true);
  picLoading = signal(true);
  popLoading = signal(true);
  soonLoading = signal(true);
  festLoading = signal(true);
  artLoading = signal(true);
  plaLoading = signal(true);

  $isMapLoaded = new BehaviorSubject(false);
  isMapLoaded$ = this.$isMapLoaded.asObservable();

  constructor() {}

  public getHeroBanners(): Observable<Array<any>> {
    this.banLoading.set(true);
    return this.http.get<Array<any>>(`${environment.apiHost}Menu/sliders`).pipe(
      map((response: any) => response.data),
      map((data) => data.sort((x, y) => (x.index - y.index < 0 ? -1 : 0))),
      tap(() => this.banLoading.set(false)),
      catchError(() => {
        this.banLoading.set(false);
        return of([]);
      })
    );
  }

  public getPicksForYou() {
    this.picLoading.set(true);
    return this.currentCityService.cityOptions$.pipe(
      tap(() => this.picLoading.set(true)),
      switchMap(() =>
        this.http
          .get<Array<any>>(`${environment.apiHost}Event/user/suggested`)
          .pipe(
            map((response: any) => response?.data),
            catchError(() => {
              return of([]);
            }),
            tap(() => this.picLoading.set(false))
          )
      )
    );
  }
  public getPopularsInCity() {
    this.popLoading.set(true);
    return this.currentCityService.cityOptions$.pipe(
      tap(() => this.popLoading.set(true)),
      switchMap(() =>
        this.http
          .get<Array<any>>(`${environment.apiHost}Event/city/suggested`)
          .pipe(
            map((response: any) => response.data),
            catchError(() => {
              return of([]);
            }),
            tap(() => this.popLoading.set(false))
          )
      )
    );
  }
  public getCommingSoonEvents(city?: string) {
    this.soonLoading.set(true);
    return this.currentCityService.cityOptions$.pipe(
      tap(() => this.soonLoading.set(true)),
      switchMap(() =>
        this.http
          .get<Array<any>>(`${environment.apiHost}Event/comingsoon`)
          .pipe(
            map((response: any) => response.data),
            catchError(() => {
              return of([]);
            }),
            tap(() => this.soonLoading.set(false))
          )
      )
    );
  }
  public getFestivals() {
    this.festLoading.set(true);
    return this.currentCityService.cityOptions$.pipe(
      tap(() => this.festLoading.set(true)),
      switchMap(() =>
        this.http.get<Array<any>>(`${environment.apiHost}Event/festivals`).pipe(
          map((response: any) => response.data),
          catchError(() => {
            return of([]);
          }),
          tap(() => this.festLoading.set(false))
        )
      )
    );
  }
  public getArtists() {
    this.artLoading.set(true);
    return this.currentCityService.cityOptions$.pipe(
      tap(() => this.artLoading.set(true)),
      switchMap(() =>
        this.http
          .get<Array<any>>(`${environment.apiHost}Artist/suggested`)
          .pipe(
            map((response: any) => response.data),
            catchError(() => {
              return of([]);
            }),
            tap(() => this.artLoading.set(false))
          )
      )
    );
  }
  public getPlaces() {
    this.plaLoading.set(true);
    return this.currentCityService.cityOptions$.pipe(
      tap(() => this.plaLoading.set(true)),
      switchMap(() =>
        this.http
          .get<Array<any>>(
            `${
              environment.apiHost
            }Place/${this.currentCityService.city()}/suggested`
          )
          .pipe(
            map((response: any) => response.data),
            catchError(() => {
              return of([]);
            }),
            tap(() => this.plaLoading.set(false))
          )
      )
    );
  }
}
