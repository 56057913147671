import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export const mockApiInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const url = request.url;
  if (url.startsWith('/mock-api')) {
    let [urlWithoutQuery, query] = url.split('?');
    urlWithoutQuery = `${urlWithoutQuery}.json`;
    const cloned = request.clone({
      url: `${urlWithoutQuery}${query ? '?' : ''}${query ?? ''}`,
    });

    return next(cloned);
  }
  return next(request);
};
