import { ResolveFn } from '@angular/router';
import { environment } from '../environments/environment';
import { inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { JsonLdService } from '../services/json-ld.service';

export const seoResolver: ResolveFn<boolean> = (route, state) => {
  const metaService = inject(Meta);
  const jsonLdService = inject(JsonLdService);
  metaService.addTag({
    name: 'robots',
    content: !environment.production ? 'noindex, nofollow' : 'index, follow',
  });
  jsonLdService.removeJsonLd();
  return true;
};
