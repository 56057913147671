export const eventTypes = [
  'concert',
  'cinema',
  'festival',
  'exhibition',
  'theater',
  'stand-up',
];

export const dataTypeMap: any = {
  EVENT: 'events',
  VENUE: 'places',
  ARTIST: 'artists',
  ORGANIZATION: 'organizators',
};
