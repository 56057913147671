import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BackButtonDirective } from '../../directives/back-button.directive';

@Component({
  selector: 'legal',
  standalone: true,
  imports: [RouterLink, BackButtonDirective],
  templateUrl: './legal.component.html',
  styleUrl: './legal.component.scss',
})
export class LegalComponent {
  public activatedRoute = inject(ActivatedRoute);
}
