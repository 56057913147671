import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  protected $categories = new BehaviorSubject<Array<any>>([]);
  protected categories$ = this.$categories.asObservable();
  protected http = inject(HttpClient);

  public getCategories() {
    const categories = this.$categories.getValue();
    if (!categories?.length) {
      this.http
        .get<any>(`${environment.apiHost}Category`)
        .pipe(
          map((response: any) =>
            response.data.sort((x: any, y: any) => (x.index < y.index ? -1 : 1))
          )
        )
        .subscribe((data) => {
          this.$categories.next(data);
        });
    }
    return this.categories$;
  }
}
