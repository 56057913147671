<mega-menu
  class="z-10"
  [hideCategories]="data()?.hideCategories"
  [class.!hidden]="data()?.hideHeader"
></mega-menu>
<router-outlet (activate)="onRouteChange($event)" />
@if(isSmallScreen$ | async){
<sticky-mobile-menu
  [class.!hidden]="data()?.hideStickyBar"
></sticky-mobile-menu>
} @defer {
<footer></footer>
}
