import { inject } from '@angular/core';
import { CanActivateFn, CanActivateChildFn } from '@angular/router';
import { switchMap, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';

export const onlyGuestGuard: CanActivateFn | CanActivateChildFn = (
  route,
  state
) => {
  const navigationService = inject(NavigationService);
  // Check the authentication status
  return inject(AuthService)
    .check()
    .pipe(
      switchMap((authenticated) => {
        // If the user is not authenticated...
        if (authenticated) {
          navigationService.back();
        }
        // Allow the access
        return of(true);
      })
    );
};
