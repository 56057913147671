import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];
  protected localizeRouterService = inject(LocalizeRouterService);

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back() {
    this.history.pop(); // Remove the current url
    if (this.history.length > 0) {
      this.router.navigateByUrl(this.history[this.history.length - 1]);
    } else {
      this.router.navigate(
        this.localizeRouterService.translateRoute(['/']) as string[]
      ); // Fallback to the home page or a default route
    }
  }
}
