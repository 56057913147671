<h1
  class="sticky z-1 top-14 left-0 bg-black text-white w-full font-syne text-center text-xl capitalize pb-4"
>
  {{ activatedRoute.snapshot.data["pageData"].title }}
  <a class="absolute left-4 top-1 liga-arrow-left" backButton></a>
</h1>
<div
  class="flex flex-col p-4 pb-16 gap-4"
  [innerHTML]="activatedRoute.snapshot.data['pageData'].content"
></div>
