import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JsonLdService {
  protected renFac2 = inject(RendererFactory2);
  protected ren2 = this.renFac2.createRenderer(null, null);

  protected schemaLoader = {
    place: () => import('../schemas/place.schema').then((m) => m.PlaceSchema),
    event: () => import('../schemas/event.schema').then((m) => m.EventSchema),
    organization: () =>
      import('../schemas/organization.schema').then(
        (m) => m.OrganizationSchema
      ),
    artist: () =>
      import('../schemas/artist.schema').then((m) => m.ArtistSchema),
  };

  async addJsonLd(data, type) {
    this.removeJsonLd();
    const schema = await this.schemaLoader[type]();
    const schemaData = schema.getSchema(data);
    const script = this.ren2.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(schemaData);
    this.ren2.appendChild(document.head, script);
  }

  removeJsonLd() {
    const existingScripts = document.querySelectorAll(
      'script[type="application/ld+json"]'
    );
    existingScripts.forEach((script) => {
      this.ren2.removeChild(document.head, script);
    });
  }
}
