export class StringHelper {
  public static slugify(str: string) {
    return (
      (str || '')
        .replace(/ı/g, 'i')
        // split an accented letter in the base letter and the acent
        .normalize('NFD')
        // remove all previously split acents
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        // remove all chars not letters, dashes, numbers and spaces
        .replace(/[^a-z0-9\s\-\.]/g, '')
        .trim()
        .replace(/\s+/g, '-')
        .replace(/\-+/g, '-')
        .replace(/\.+/g, '.')
        .replace(/^\-+|\-+$/g, '')
        .replace(/^\.+|\.+$/g, '')
    );
  }
}
