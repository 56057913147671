import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  tap,
  throwError,
} from 'rxjs';
import { environment } from '../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  protected httpClient = inject(HttpClient);
  protected toastrService = inject(ToastrService);
  protected translate = inject(TranslateService);
  public $user = new BehaviorSubject<any>(null);
  set user(value: any) {
    // Store the value
    this.$user.next(value);
  }
  get user() {
    return this.$user.getValue();
  }

  get user$(): Observable<any> {
    return this.$user.asObservable().pipe(
      tap((user) => {
        if (!user) {
          this.get().subscribe();
        }
      })
    );
  }

  get(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiHost}User/info`).pipe(
      map((response) => response.data),
      tap((user) => {
        this.$user.next(user);
      })
    );
  }
  update(data) {
    return this.httpClient.put(`${environment.apiHost}User`, data).pipe(
      map((response: any) => response?.data),
      tap(() =>
        this.toastrService.success(
          this.translate.instant('successMsg.userUpdate')
        )
      ),
      catchError((err) => {
        if (err.error.errors) {
          this.toastrService.error(Object.values(err.error.errors).join(' '));
        } else if (err.error.error) {
          this.toastrService.error(err.error.error.messages.join(' '));
        } else {
          this.toastrService.error(this.translate.instant('errorMsg.generic'));
        }
        return throwError(err);
      })
    );
  }
  changePassword(data) {
    return this.httpClient
      .post(`${environment.apiHost}User/change-password-for-auth-user`, data)
      .pipe(
        map((response: any) => response?.data),
        tap(() =>
          this.toastrService.success(
            this.translate.instant('successMsg.changePassword')
          )
        ),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }
}
