import { Component, Input, OnInit, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { AddCdnPipe } from '../../pipes/add-cdn.pipe';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'image',
  standalone: true,
  imports: [AddCdnPipe, NgOptimizedImage],
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss',
})
export class ImageComponent implements OnInit {
  @Input() src = '';
  @Input() width: number = 0;
  @Input() height = 0;
  @Input() alt = '';
  @Input() priority = false;

  isLoading = true;

  cdn = environment.cdn;

  public imageLoadSuccess = signal(true);

  constructor() {}

  ngOnInit(): void {
    this.isLoading = true;
  }

  onImageLoadError(event: any) {
    this.imageLoadSuccess.set(false);
  }
  onLoadEnd() {
    this.isLoading = false;
  }
}
