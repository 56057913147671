import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthHelper } from '../helpers/auth.helper';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  map,
  Observable,
  of,
  shareReplay,
  tap,
  throwError,
} from 'rxjs';
import { UserService } from './user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  protected http = inject(HttpClient);
  protected userService = inject(UserService);
  protected toastrService = inject(ToastrService);
  protected translate = inject(TranslateService);
  protected token = signal('');
  protected $accessToken = new BehaviorSubject(
    localStorage.getItem('accessToken') ?? ''
  );

  public accessToken$ = this.$accessToken
    .asObservable()
    .pipe(distinctUntilChanged());

  authenticated = false;

  set accessToken(token: string) {
    this.$accessToken.next(token);
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  signIn(credentials: any, type: 'email' | 'phone') {
    credentials = { ...credentials };
    let req = null;
    if (type === 'phone') {
      delete credentials.email;
      req = this.http.post(
        `${environment.apiHost}Authentication/signin-with-phone`,
        credentials
      );
    } else {
      delete credentials.phoneNumber;
      req = this.http.post(
        `${environment.apiHost}Authentication/signin`,
        credentials
      );
    }
    return req.pipe(
      map((response: any) => response.data),
      tap((data: any) => {
        this.accessToken = data.token;
        this.refreshToken = data.refreshToken;
        const user = { ...data.user };

        // Set the authenticated flag to true
        this.userService.user = user;
        this.authenticated = true;
        this.toastrService.success(this.translate.instant('successMsg.login'));
      }),
      catchError((err) => {
        if (err.error.errors) {
          this.toastrService.error(Object.values(err.error.errors).join(' '));
        } else if (err.error.error) {
          this.toastrService.error(err.error.error.messages.join(' '));
        } else {
          this.toastrService.error(this.translate.instant('errorMsg.generic'));
        }
        return throwError(err);
      }),
      shareReplay(1)
    );
  }
  check() {
    return this.accessToken$.pipe(
      map((token) => {
        return token ? !AuthHelper.isTokenExpired(token) : false;
      })
    );
  }
  register(credentials: any) {
    return this.http
      .post(`${environment.apiHost}Authentication/register`, credentials)
      .pipe(
        map((response: any) => response.data),
        tap((data) => {
          this.accessToken = data.token;
          this.refreshToken = data.refreshToken;
          const user = {
            name: data.name,
            surname: data.surname,
            email: data.email,
            phone: data.phone,
          };

          // Set the authenticated flag to true
          this.userService.user = user;
          this.authenticated = true;
          this.toastrService.success(
            this.translate.instant('successMsg.register')
          );
        }),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        }),
        shareReplay(1)
      );
  }
  sendVerificationCodeRegister(): Observable<string> {
    return this.http
      .put(`${environment.apiHost}Authentication/send-code`, null)
      .pipe(
        map((response: any) => response.data),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }
  signOut() {
    // Set the authenticated flag to false
    this.authenticated = false;

    this.accessToken = '';
    this.refreshToken = '';
    this.toastrService.success(this.translate.instant('successMsg.logout'));
  }

  verifyPhoneRegister(otp) {
    return this.http
      .put(`${environment.apiHost}Authentication/verify-code/${otp}`, null)
      .pipe(
        map((response: any) => response.data),
        tap((data) => {
          this.accessToken = data.token;
          this.refreshToken = data.refreshToken;
          const user = {
            name: data.name,
            surname: data.surname,
            email: data.email,
            phone: data.phone,
          };

          // Set the authenticated flag to true
          this.userService.user = user;
          this.authenticated = true;
          this.toastrService.success(
            this.translate.instant('successMsg.phoneVerified')
          );
        }),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }

  forgotPasword(phone) {
    return this.http
      .post(`${environment.apiHost}User/forgot-password`, { phone })
      .pipe(
        map((response: any) => response.data),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }

  validateOtp(phone, otp) {
    return this.http
      .post(`${environment.apiHost}User/validate-otp`, { phone, otp })
      .pipe(
        map((response: any) => response.data),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }

  changePassword(accessToken, password) {
    return this.http
      .post(`${environment.apiHost}User/change-password`, {
        accessToken,
        password,
      })
      .pipe(
        map((response: any) => response.data),
        tap(() =>
          this.toastrService.success(
            this.translate.instant('successMsg.changePassword')
          )
        ),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }
}
