import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../environments/environment';

@Pipe({
  name: 'addCdn',
  standalone: true,
})
export class AddCdnPipe implements PipeTransform {
  transform(value: string): unknown {
    return value
      ? value?.startsWith('http') || value?.startsWith('data')
        ? value
        : `${environment.cdn}${value}`
      : '/assets/images/placeholder.jpg';
  }
}
