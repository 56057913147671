<div class="text-lg md:text-xl flex items-start py-4">
  <div>{{ "citySelectionTitle" | translate }}</div>
  <button (click)="close()" class="liga-cross ml-auto"></button>
</div>

<div class="flex flex-col">
  <input
    type="text"
    [placeholder]="'citySearchPlaceHolder' | translate"
    [formControl]="searchInput"
    class="text-base py-3 px-4.5 border border-black rounded-none w-full"
  />
  <label class="mt-2.5 flex items-center">
    <input
      type="checkbox"
      [formControl]="nearByCities"
      class="accent-black w-4 h-4 mr-2"
    />
    <span>{{ "searchNerByCities" | translate }}</span>
  </label>
</div>
<div class="flex flex-col overflow-auto max-h-full">
  @for(item of filteredCities(); track item){
  <button
    class="px-4 py-4 border-b first:border-t border-gray-500 hover:bg-black hover:bg-opacity-15 text-start"
    [class.bg-black]="selectedCity === item.name"
    [class.text-white]="selectedCity === item.name"
    [class.hover:bg-opacity-80]="selectedCity === item.name"
    [class.hover:text-white]="selectedCity === item.name"
    (click)="onSelect(item)"
  >
    {{ item.name }}
  </button>
  }
</div>
