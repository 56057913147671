import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LegalService {
  protected http = inject(HttpClient);

  getPrivacy() {
    return this.http
      .get(`${environment.apiHost}Corporate/privacy`)
      .pipe(map((response: any) => response.data));
  }

  getTerms() {
    return this.http
      .get(`${environment.apiHost}Corporate/terms`)
      .pipe(map((response: any) => response.data));
  }

  getKVKK() {
    return this.http
      .get(`${environment.apiHost}Corporate/kvkk`)
      .pipe(map((response: any) => response.data));
  }
}
