import { Routes } from '@angular/router';
import { LayoutComponent } from '../modules/layout/layout.component';
import { seoResolver } from '../resolvers/seo.resolver';
import { AuthGuard } from '../guards/auth.guard';
import { LegalComponent } from '../pages/legal/legal.component';
import { legalPagesResolver } from '../resolvers/legal-pages.resolver';
import { onlyGuestGuard } from '../guards/auth.guard copy';
import { ComingSoonComponent } from '../pages/coming-soon/coming-soon.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '' },
  {
    path: '',
    component: LayoutComponent,
    resolve: {
      robots: seoResolver,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('../pages/home/home.routes'),
      },
      {
        path: 'explore',
        loadChildren: () => import('../pages/explore/explore.routes'),
      },
      {
        path: 'events',
        loadChildren: () => import('../pages/events/events.routes'),
      },
      {
        path: 'places',
        loadChildren: () => import('../pages/places/places.routes'),
      },
      {
        path: 'artists',
        loadChildren: () => import('../pages/artists/artists.routes'),
      },
      {
        path: 'organizators',
        loadChildren: () => import('../pages/organizators/organizators.routes'),
      },
    ],
  },
  {
    path: 'signIn',
    data: {
      hideHeader: true,
      hideStickyBar: false,
    },
    canActivate: [onlyGuestGuard],
    canActivateChild: [onlyGuestGuard],
    loadComponent: () =>
      import('../pages/sign-in/sign-in.component').then(
        (m) => m.SignInComponent
      ),
  },
  {
    path: 'register',
    data: {
      hideHeader: true,
      hideStickyBar: false,
    },
    canActivate: [onlyGuestGuard],
    canActivateChild: [onlyGuestGuard],
    loadComponent: () =>
      import('../pages/register/register.component').then(
        (m) => m.RegisterComponent
      ),
  },
  {
    path: 'forgotPassword',
    data: {
      hideHeader: true,
      hideStickyBar: false,
    },
    canActivate: [onlyGuestGuard],
    canActivateChild: [onlyGuestGuard],
    loadComponent: () =>
      import('../pages/forgot-password/forgot-password.component').then(
        (m) => m.ForgotPasswordComponent
      ),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../pages/profile/profile.routes'),
  },

  {
    path: 'privacy',
    data: {
      hideCategories: true,
      key: 'privacy',
    },
    resolve: {
      pageData: legalPagesResolver,
    },
    component: LegalComponent,
  },
  {
    path: 'terms',
    data: {
      hideCategories: true,
      key: 'terms',
    },
    resolve: {
      pageData: legalPagesResolver,
    },
    component: LegalComponent,
  },
  {
    path: 'kvkk',
    data: {
      hideCategories: true,
      key: 'kvkk',
    },
    resolve: {
      pageData: legalPagesResolver,
    },
    component: LegalComponent,
  },
  {
    path: 'wallet',
    data: { hideCategories: true, descKey: 'wallet' },
    component: ComingSoonComponent,
  },
];
