import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { CurrentCityService } from '../services/current-city.service';
import { environment } from '../environments/environment';

export const cityInterceptor: HttpInterceptorFn = (request, next) => {
  if (
    request.url.startsWith(environment.apiHost) &&
    !environment.multiCityDisabled
  ) {
    const cityService = inject(CurrentCityService);
    let [urlWithoutQuery, query] = request.url.split('?');
    const searchParams = new URLSearchParams(query || '');

    searchParams.append('cityName', cityService.city() || 'Türkiye');
    searchParams.append(
      'searchNearByCities',
      JSON.stringify(cityService.nearByCities())
    );
    const url = `${urlWithoutQuery}?${decodeURIComponent(
      searchParams.toString()
    )}`;
    const cloned = request.clone({
      url,
    });

    return next(cloned);
  }
  return next(request);
};
