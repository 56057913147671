import { Location } from '@angular/common';
import { Directive, HostBinding, HostListener, inject } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Directive({
  selector: '[backButton]',
  standalone: true,
})
export class BackButtonDirective {
  protected location = inject(Location);
  protected navigationService = inject(NavigationService);

  @HostBinding('class.cursor-pointer') cursorPointer = true;

  @HostListener('click') onClick() {
    this.navigationService.back();
  }
}
