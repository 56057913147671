import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'sticky-mobile-menu',
  standalone: true,
  imports: [TranslateModule, LocalizeRouterModule, RouterLink],
  templateUrl: './sticky-mobile-menu.component.html',
  styleUrl: './sticky-mobile-menu.component.scss',
})
export class StickyMobileMenuComponent {}
